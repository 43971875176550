import axios from 'axios';
import { DATA_KEYS, HTTPS_STATUS_CODE_KEYS, MESSAGE_KEYS } from '../../utils/constants/selfServe';

// axios client for self serve apis
const axiosClientSelfServe = axios.create({
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    }
});

//transforms the api response format to a common format
axiosClientSelfServe.interceptors.response.use(response => {
    const statusCodeKey = HTTPS_STATUS_CODE_KEYS.find(key => key in response.data);
    const dataKey = DATA_KEYS.find(key => key in response.data);
    const messageKey = MESSAGE_KEYS.find(key => key in response.data);

    response.data.statusCode = response.data[statusCodeKey];
    response.data.data = response.data[dataKey];

    if(Array.isArray(response.data[messageKey]))
        response.data.message = response.data[messageKey].join("-");
    else
        response.data.message = response.data[messageKey]

    delete response.data[statusCodeKey];
    delete response.data[dataKey];
    delete response.data[messageKey];
}, 
error => {
    const statusCodeKey = HTTPS_STATUS_CODE_KEYS.find(key => key in error.response.data);
    const dataKey = DATA_KEYS.find(key => key in error.response.data);
    const messageKey = MESSAGE_KEYS.find(key => key in error.response.data);

    error.response.data.statusCode = error.response.data[statusCodeKey];
    error.response.data.data = error.response.data[dataKey];

    if(Array.isArray(error.response.data[messageKey]))
        error.response.data.message = error.response.data[messageKey].join("-");
    else
        error.response.data.message = error.response.data[messageKey]

    delete error.response.data[statusCodeKey];
    delete error.response.data[dataKey];
    delete error.response.data[messageKey];

    return Promise.reject(error);
})


export default axiosClientSelfServe;